<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">测量记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">体重、瘤径记录</h4>
        <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,2)">返回列表页</el-button>
      </div>
      <el-form ref="addForm" size="small" :model="measure" class="addForm" label-suffix=":"
               label-width="90px">
        <el-form-item label="操作室地点">
          <el-input v-model="measure.location" placeholder="请输入操作室地点" clearable class="smallWidth"></el-input>
        </el-form-item>
        <el-form-item label="使用仪器">
          <div v-for="(obj,ind) in measure.instrument" :key="ind" class="modeFor">
            <el-input v-model="obj.instrumentName" :placeholder='"请输入仪器"+(ind+1)+"名称"' clearable
                      class="smallWidth"></el-input>
            <el-input v-model="obj.instruModel" :placeholder='"请输入仪器"+(ind+1)+"型号"' clearable
                      class="smallWidth"></el-input>
            <el-button size="small" plain class="delIns" icon="el-icon-close" @click="delIns(ind)"></el-button>
          </div>
          <el-button icon="el-icon-plus" class="addInstru" @click="addInstru">添加仪器</el-button>
        </el-form-item>
        <!--        新增-->
        <template v-if="state==null">
          <el-tabs v-model="tabsValue" class="tabsCage">
            <el-tab-pane
                :key="index"
                v-for="(item, index) in tabs"
                :name="item.name"
            >
              <span slot="label">{{ item.name }}</span>
              <el-table
                  :data="item.tablelList" border
                  class="tableMember"
                  :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                  style="width: calc(100% - 12px);font-size:14px;color:#333">
                <el-table-column label="笼号" prop="cageNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
                <el-table-column label="性别">
                  <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
                </el-table-column>
                <el-table-column label="体重(g)" class-name="isClass">
                  <template slot-scope="scope">
                    <el-input size="small" placeholder="请输入体重" v-model="scope.row.weight"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="瘤径a(mm)" width="160" class-name="isClass">
                  <template slot-scope="scope">
                    <el-input size="small" placeholder="请输入瘤径a" v-model="scope.row.tumorSizeA"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="瘤径b(mm)" class-name="isClass">
                  <template slot-scope="scope">
                    <el-input size="small" placeholder="请输入瘤径b" v-model="scope.row.tumorSizeB"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </template>
        <!--编辑-->
        <template v-else>
          <el-tabs v-model="nowNumber" class="tabsCage">
            <el-tab-pane
                :key="key"
                v-for="(item,key) in tabsSave"
                :name="key"
            >
              <span slot="label">{{ key }}</span>
              <el-table
                  :data="item" border
                  class="tableMember"
                  :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                  style="width: calc(100% - 12px);font-size:14px;color:#333">
                <el-table-column label="笼号" prop="cageNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
                <el-table-column label="性别">
                  <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
                </el-table-column>
                <el-table-column label="体重(g)">
                  <template slot-scope="scope">
                    <template v-if="scope.row.show">
                      <el-input size="small" placeholder="请输入体重"
                                v-model="scope.row.weight"></el-input>
                    </template>
                    <span v-else>{{ scope.row.weight }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="瘤径a(mm)" width="160">
                  <template slot-scope="scope">
                    <template v-if="scope.row.show">
                      <el-input size="small" placeholder="请输入瘤径a"
                                v-model="scope.row.tumorSizeA"></el-input>
                    </template>
                    <span v-else>{{ scope.row.tumorSizeA }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="瘤径b(mm)">
                  <template slot-scope="scope">
                    <template v-if="scope.row.show">
                      <el-input size="small" placeholder="请输入瘤径b"
                                v-model="scope.row.tumorSizeB"></el-input>
                    </template>
                    <span v-else>{{ scope.row.tumorSizeB }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" v-if="state==0||state==3">
                  <template slot-scope="scope">
                    <div class="editColor">
                      <el-button type="text" v-if="!scope.row.show" @click="edit(scope.row,scope.$index,item)">编辑
                      </el-button>
                      <el-button type="text" v-else @click="save(scope.row,scope.$index,item)">保存</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </template>
        <h3 class="tit">指定复核人</h3>
        <el-form-item label="复核人员">
          <el-radio-group v-model="measure.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="submitForm(1)">记录完成</el-button>
          <el-button type="primary" plain size="small" @click="submitForm(0)">保存</el-button>
          <template v-if="measureId">
            <el-button plain type="primary" size="small" @click="$emit('changePage', 3,measureId,2)">导出预览</el-button>
          </template>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: "measurement",
  props: ['measureId', 'id'],
  data() {
    return {
      measure: {
        location: '',
        instrument: [],
        reviewer: null,
        reviewName: '',
        measureDetailList: []
      },
      state: null,
      // 新增
      tabsValue: '',
      tabs: [],
      userList: [],
      // 保存或者提交
      tabsSave: {},
      nowNumber: 0,
    }
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    // 获取详情
    if (this.measureId) {
      this.getMeasuDetail()
    } else {
      this.getGroup()
    }
  },
  methods: {
    // 指定复核人员
    getAppId(id, name) {
      this.measure.reviewer = id
      this.measure.reviewName = name
    },
    addInstru() {
      this.measure.instrument.push({
        instrumentName: "",
        instruModel: ""
      })
    },
    delIns(index) {
      this.measure.instrument.splice(index, 1)
    },
    // 新增--获取实验组信息
    getGroup() {
      this.$get('/group/' + this.id).then(res => {
        if (res.status == 200) {
          res.data.forEach(item => {
            item['tablelList'] = []
            item.groupCageList.forEach(obj => {
              item['tablelList'].push({
                aniNo: obj.aniNo,
                cageNo: obj.cageNo,
                gender: obj.gender,
                groupAniId: obj.groupAniId,
                groupId: obj.groupId,
                groupName: item.name,
                overbit: obj.overbit,
                weight: "",
                tumorSizeA: "",
                tumorSizeB: "",
                remark: ""
              })
            })
          })
          this.tabs = res.data
          this.tabsValue = res.data[0].name
        }
      })
    },
    // 修改提交--获取详情
    getMeasuDetail() {
      this.$get('/subject/measure/' + this.measureId).then(res => {
        if (res.status == 200) {
          this.state = res.data.measure.state
          this.measure = {
            location: res.data.measure.location,
            instrument: JSON.parse(res.data.measure.instrument),
            reviewer: res.data.measure.reviewer,
            reviewName: res.data.measure.reviewName,
          }
          this.tabsSave = res.data.groupMap
          let arr = []
          if (this.nowNumber == 0) {
            for (const key in res.data.groupMap) {
              arr.push(key)
              res.data.groupMap[key].map(i => {
                i.show = false
                return i
              })
            }
            this.nowNumber = arr[0]
          }
        }
      })
    },
    // 编辑
    edit(row, index, table) {
      row.show = !row.show
      this.$set(table, index, row)
    },
    // 保存
    save(row, index, table) {
      this.$put('/subject/measure/detail', row).then(res => {
        if (res.status == 200) {
          this.$message.success("修改成功")
          row.show = false
          this.$set(table, index, row)
        }
      })
    },
    // 修改后获取
    getUpdate(id) {
      // groupId组id
      this.$get('/subject/measure/detail/' + this.measureId, {groupId: id})
    },
    // 提交信息
    submitForm(state) {
      if ($.trim(this.measure.reviewer).length === 0) {
        this.$message.warning("请选择复核人")
        return false;
      }
      this.measure.state = state
      this.measure.subId = Number(this.id)
      this.measure.instrument = JSON.stringify(this.measure.instrument)
      let url;
      if (this.state == null) {
        let lists = [];
        this.tabs.forEach(item => {
          item.tablelList.forEach(obj => {
            let newObj = {
              aniNo: obj.aniNo,
              cageNo: obj.cageNo,
              gender: obj.gender,
              groupAniId: obj.groupAniId,
              groupId: obj.groupId,
              groupName: item.name,
              overbit: obj.overbit,
              weight: obj.weight,
              tumorSizeA: obj.tumorSizeA,
              tumorSizeB: obj.tumorSizeB,
              remark: obj.remark
            }
            lists.push(newObj)
          })
        })
        this.measure.measureDetailList = lists

        // 新增
        url = this.$postJson('/subject/measure', this.measure)
      } else {
        // 修改
        this.$delete(this.measure, 'measureDetailList')
        this.measure.id = this.measureId
        url = this.$putJson('/subject/measure', this.measure)
      }
      url.then(res => {
        if (res.status == 201 || res.status == 200) {
          this.$message.success('提交成功')
          this.$emit('changePage', 1, null, 2)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
